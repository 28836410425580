import React, { createContext, useContext, useMemo } from 'react';
import {useAuth} from "@/shared/libs/auth";

const WatermarkContext = createContext();

export const WatermarkProvider = ({children }) => {
    const { user } = useAuth();
    let username = '';
    if(user){
        username = user.name.concat('(',user.empNo,')');
    }
    const watermarkValue = useMemo(() => ({ username }), [username]);
    return (
        <WatermarkContext.Provider value={watermarkValue}>
            {children}
        </WatermarkContext.Provider>
    );
};

export const useWatermark = () => {
    return useContext(WatermarkContext);
};

