import React, { Suspense, PropsWithChildren } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HashRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HotkeysProvider } from "@blueprintjs/core";
import { SWRConfig } from "swr";

import { queryClient } from "@/shared/libs/react-query";
import { AuthProvider } from "@/shared/libs/auth";
import { NavigationProvider } from "@/shared/libs/navigation";
import { KeepAliveProvider } from "@/shared/libs/keepAlive";

import { isDev } from "@/config";
import Watermark from "@/shared/watermark/watermark";
import {WatermarkProvider} from "@/shared/watermark/watermark-context";

const ErrorFallback = (props: FallbackProps) => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">{props.error.message}</h2>
      <button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </button>
    </div>
  );
};

export const AppProviders = (props: PropsWithChildren) => {
  return (
    <Suspense>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SWRConfig value={{ revalidateOnFocus: false, errorRetryCount: 0 }}>
          <QueryClientProvider client={queryClient}>
            {isDev && <ReactQueryDevtools position="bottom-right" />}
            <AuthProvider>

              {/*水印*/}
              <WatermarkProvider>
                <Watermark/>

              <HotkeysProvider>
                <NavigationProvider>
                  <KeepAliveProvider>
                    <Router>{props.children}</Router>
                  </KeepAliveProvider>
                </NavigationProvider>
              </HotkeysProvider>
              </WatermarkProvider>

            </AuthProvider>
          </QueryClientProvider>
        </SWRConfig>
      </ErrorBoundary>
    </Suspense>
  );
};
