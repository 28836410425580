import React from 'react';
import { useWatermark } from './watermark-context';

const Watermark = () => {
    const { username } = useWatermark();
    const watermarkStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 9999,
        overflow:'hidden'
        // backgroundImage: `repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 1px, transparent 1px, transparent 20px)`,
    };

    const watermarkTextStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-30deg)',
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.05)',
        whiteSpace: 'nowrap',
    };

    // const numRows = Math.ceil(window.innerHeight / 50);
    // const numCols = Math.ceil(window.innerWidth / 50);

    const numRows = Math.ceil(window.innerHeight / 100);
    const numCols = Math.ceil(window.innerWidth / 200);

    const watermarkElements = [];
    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            watermarkElements.push(
                <div
                    key={`watermark-${i}-${j}`}
                    style={{
                        ...watermarkTextStyle,
                        top: `${i * 100}px`,
                        left: `${j * 200}px`
                    }}
                >
                    {username}
                </div>
            );
        }
    }

    return (
        <div style={watermarkStyle}>
            {watermarkElements}
        </div>
    );
};

export default Watermark;

