import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { token as tokenStorage, storage } from "@/shared/utils/storage";
import { toaster } from "@/shared/libs/toaster";
import {
  baseURL,
  testURL,
  responseCodeKey,
  responseDataKey,
  responseMessageKey,
  successCode,
  invalidCode,
  noPermissionCode, failCode,
} from "@/config";
import type { ResponseType } from "@/shared/types";

const axios = Axios.create({
  baseURL: !!storage.get("testFlag") ? testURL : baseURL,
});

const downloadAxios = Axios.create({
  baseURL: !!storage.get("testFlag") ? testURL : baseURL,
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = tokenStorage.getToken();
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      from: "PC",
      token,
    };
  }
  config.headers = {
    ...config.headers,
    Accept: "application/json",
  };
  return config;
};

// 返回值拦截器，当返回的 code 未非正确 code 时，抛出异常
// 通过校验则返回 data 中的 data
const responseInterceptor = (
  response: AxiosResponse<ResponseType<any>, any>
) => {
  const { data, headers } = response;
  const token = headers.token;
  if (token) tokenStorage.setToken(token);
  // 如果返回的 code 在 success code 的数组中，则返回 data 值
  if (successCode.includes(data[responseCodeKey])) {
    return data[responseDataKey];
  }
  if (invalidCode.includes(data[responseCodeKey])) {
    console.error("未登录或登录已过期，请重新登录");
    toaster.current?.show({
      message: "未登录或登录已过期，请重新登录",
      intent: "danger",
      icon: "error",
      timeout: 3000,
      action: {
        text: "重新登录",
        onClick: () => {
          tokenStorage.clearToken();
          window.location.assign(window.location.origin);
        },
      },
    });
    throw data;
  }

    toaster.current?.show({
      message: data[responseMessageKey],
      intent: "danger",
      icon: "error",
      timeout: 3000,
    });

  if (noPermissionCode.includes(data[responseCodeKey])) {
    console.error("没有权限");
    throw data;
  }
  // 抛出异常内容
  console.log(data);
  throw data;
};

// 当接口请求产生错误时，会触发该拦截器
// 即返回的 status 不为 2 开头的情况
const errorInterceptor = (error: AxiosError) => {

  if("ERR_BAD_RESPONSE" === error.code){
    toaster.current?.show({
      message: '诶呀，网络开小差啦，请稍等一下....',
      intent: "danger",
      icon: "error",
      timeout: 3000,
    });
    throw error;
  }

  const errorData = {
    [responseMessageKey]: error.message,
    [responseCodeKey]: error.code,
  };
  throw errorData;
};

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(responseInterceptor, errorInterceptor);

downloadAxios.interceptors.request.use(authRequestInterceptor);
/**
 * request 对 axios 进行了简单的类型封装（返回值）
 * 如需使用原始的 axios，使用 import { axios } 的方式获取实例
 * */
const request = {
  get: <R = AxiosResponse<any>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => {
    return axios.get<any, R>(url, config);
  },
  post: <R = AxiosResponse<any>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ) => {
    return axios.post<any, R>(url, data, config);
  },
};

export default request;
export { axios, downloadAxios };
