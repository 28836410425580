import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "@/reportWebVitals";
import { AppProviders } from "@/providers/appProviders";
import { AppRoutes } from "@/routes";

import { FocusStyleManager, Toaster } from "@blueprintjs/core";
import { toaster } from "@/shared/libs/toaster";

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

import "core-js/features/array/flat-map";
import "core-js/features/array/flat";

import "./virtual:windi.css";
import "./index.css";

import quarterOfYear from "dayjs/plugin/quarterOfYear";
import localeData from "dayjs/plugin/localeData";
import {WatermarkProvider} from "@/shared/watermark/watermark-context";
import Watermark from "@/shared/watermark/watermark";
import {useAuth} from "@/shared/libs/auth";
// const localeData = require("dayjs/plugin/localeData");
// const quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

dayjs.extend(localeData);
dayjs.locale("zh-cn");

const App = () => {
  return (

            <AppProviders>
              <AppRoutes />
            </AppProviders>

  );
};

FocusStyleManager.onlyShowFocusOnTabs();

const rootContainer = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootContainer);

const toastContainer = document.getElementById("toaster") as Element;
const toasterRoot = ReactDOM.createRoot(toastContainer);

root.render(
    <App />
);
toasterRoot.render(
  <Toaster maxToasts={1} ref={(instance) => (toaster.current = instance)} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
